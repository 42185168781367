import React from 'react';

function ChatMessages(props) {
  const { messages } = props;

  return (
    <div className="flex flex-col space-y-2">
      {messages.map((message, index) => (
        <div
          key={index}
          className={`px-5 py-4 rounded-xl ${
            message.from === 'user'
              ? 'bg-gray-200 text-gray-800 self-end'
              : 'bg-purple-200 text-gray-800 self-start'
          }`}
        >
          <div className={`text-xs ${
            message.from === 'user'
            ? 'text-gray-700'
            : 'text-purple-700'
          }`}>{message.from}</div>
          {message.text}
        </div>
      ))}
    </div>
  );
}

export default ChatMessages;
