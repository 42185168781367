import React, { useState } from 'react';

function MessageInputForm(props) {
  const [messageText, setMessageText] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    props.onSubmit(messageText);
    setMessageText('');
  };

  const handleMetrics = (event) => {
    event.preventDefault();
    props.onMetrics();
  };

  const handleInputChange = (event) => {
    setMessageText(event.target.value);
  };

  return (
    <form onSubmit={handleSubmit} className="flex justify-between items-center p-2">
      <input
        type="text"
        value={messageText}
        onChange={handleInputChange}
        className="flex-grow mr-2 px-2 py-1 rounded-xl border-gray-200 border focus:outline-none focus:ring-2 focus:ring-purple-400"
      />
      <button
        type="submit"
        className="px-4 py-2 rounded-xl bg-purple-400 text-white hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-400"
      >
        Send
      </button>
      <button
        type="primary"
        onClick={handleMetrics}
        className="ml-2 px-4 py-2 rounded-xl bg-purple-400 text-white hover:bg-purple-500 focus:outline-none focus:ring-2 focus:ring-purple-400"
      >
        Metrics
      </button>
    </form>
  );
}

export default MessageInputForm;
