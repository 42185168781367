import React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate
} from "react-router-dom";
import ChatWindow from './components/ChatWindow';

function App() {
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(0);

  const [messages, setMessages] = React.useState([
    //{ text: 'Hello!', from: 'user' },
    //{ text: 'Hi there!', from: 'JakeAI' },
    //{ text: 'How are you?', from: 'user' },
    //{ text: 'I am good, thank you!', from: 'JakeAI' },
  ]);

  const [speakers, setSpeakers] = React.useState([
    //{ name: 'jake' },
    { name: 'shahan' },
  ]);

  const [textMetrics, setTextMetrics] = React.useState([
    { category: 'Engagement', score: 5, reason: 'Jess actively engages with Shahan throughout the conversation. They respond promptly to Shahan’s comments with genuine interest and curiosity, reciprocate by sharing similar experiences from LA, and ask follow-up questions related to the topics introduced by Shahan, such as festivals and food.' },
    { category: 'Informative', score: 4, reason: "Jess contributes valuable information to the conversation, particularly about LA's landmarks, local festivals, the national flower, the food scene, and sports culture. They effectively compare and contrast their experiences in LA with Shahan's descriptions of Pakistan, thereby enriching the conversation. The reason for not scoring a 5 is that Jess could potentially offer more detailed insights or lesser-known facts to make the conversation even more informative."  },
    { category: 'Grammar', score: 3, reason: 'Jess uses clear, well-structured sentences with correct grammar throughout the conversation. There are no apparent errors, and the flow of their dialogue is smooth, contributing to an easy-to-follow and coherent exchange.' },
    { category: 'Concise', score: 4, reason: "Jess's responses are generally concise and to the point, effectively communicating their thoughts without unnecessary elaboration. They manage to convey meaningful information in a few sentences. However, there might be room for even more concise expression or denser information sharing in some parts of the conversation." },
    { category: 'Confidence', score: 5, reason: 'Despite the friendly and casual context of their conversation, Jess maintains a polite and respectful tone throughout, which contributes to a positive and professional dialogue manner. They effectively balance informality with respect and interest, which is suitable for the nature of this exchange.' },
  ]);

  const [audioMetrics, setAudioMetrics] = React.useState([
    
  ]);

  const [selectedSpeaker, setSelectedSpeaker] = React.useState('shahan');

  const handleMetrics = () => {
    console.log("Metrics button clicked");

    // convert messages to text
    var msgText = "";
    for(var i = 0; i < messages.length; i++) {
      const msg = messages[i].text;
      const usr = messages[i].from;
      msgText += usr + ": " + msg + "\n";
    }

    setLoading(1);
    navigate('/metrics');

    // query metrics api and print response
    fetch('http://127.0.0.1:8787/metricsapi', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        //"Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify({"conversation": msgText, key:"62979960-bd76-4afb-941f-949f9509d7e8"}),
    }).then(response => {
      return response.json();
    }).then(json => {
          
      console.log("Metrics: "+json.metrics);
      setTextMetrics(json.metrics);
      setLoading(0);      
    });
  };  
  const handleSubmit = (messageText) => {
    const newMessage = { text: messageText, from: 'user' };
  
    var aiMessageText = "";
    var aiMessage = { text: aiMessageText, from: selectedSpeaker + "AI" };
    setMessages([...messages, newMessage, aiMessage]);
  
    // Prepare the messages array for the API request
    const apiMessages = messages.slice(-5).map(msg => ({
      type: msg.from === 'user' ? 'user' : 'ai',
      message: msg.text
    }));
  
    apiMessages.push({ type: 'user', message: messageText });
  
    // Send the messages array to the AI API
    fetch('https://rhetoriqai-chatgpt-dev.catliadotnet.workers.dev/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        messages: apiMessages,
        key: "62979960-bd76-4afb-941f-949f9509d7e8",
        speaker: selectedSpeaker
      }),
    })
      .then(response => {
        return response.json();
      })
      .then(text => {
        console.log("Response: " + text.message);
        aiMessage.text = text.message;
        setMessages([...messages, newMessage, aiMessage]);
  
        // Get audio file from text to speech and play it
        var audio = new Audio("https://tts.jakes.au/synthesize?key=62979960-bd76-4afb-941f-949f9509d7e8&speaker="+selectedSpeaker+"&text="+text.message);
        audio.play();
      });
  };

  function hide(loading) {
    if (loading) {
      return 'hidden';
    } else {
      return '';
    }
  }

  function scoreToColor(score) {
    if (score >= 4) {
      return "text-success";
    } else if (score >= 3) {
      return "text-warning";
    } else {
      return "text-error";
    }
  }

  return (
    <div>
      <Routes>
        <Route path="/metrics" element={<div className="container mx-auto my-8">     
              <div className='p-4'>
                <div className='text-3xl'>RhetoriqAI Metrics Demo</div>
                <div className={`grid grid-cols-3 gap-4 pt-5 text-xl ${hide(!loading)}`}>
                  Loading metrics for conversation...
                </div>
                <div className={`grid grid-cols-3 gap-4 pt-5 ${hide(loading)}`}>
                    {textMetrics.map((metric) => (
                      <div className="collapse collapse-arrow peer-checked:bg-base-200 text-4xl text-center bg-base-300 card">
                        <input type="checkbox" className="peer" />
                        <div className='collapse-title text-xl pb-4 text-base-content'>
                          <div> {metric.category} </div>
                          <div className='text-4xl'> {metric.score} / 5</div>
                        </div>
                        <div className={`collapse-content text-sm  pt-4 ${scoreToColor(metric.score)}`}> {metric.reason}</div>
                      </div>
                    ))}
                    {audioMetrics.map((metric) => (
                      <div className="collapse collapse-arrow text-4xl p-2 peer-checked:bg-base-200 text-center bg-base-300 rounded-lg">
                        <input type="checkbox" className="peer" />
                        <div className='collapse-title text-xl pt-4'>
                          <div>{metric.category} </div>
                          <div className='text-4xl'> {metric.score} / 5</div>
                        </div>
                        
                        <div className={`collapse-content text-sm  pt-4 ${scoreToColor(metric.score)}`}>{metric.reason}</div>
                      </div>
                    ))}
                    <div className="text-4xl p-2 text-center pb-10 bg-base-300 rounded-lg">
                        {/* Calculate total score from text metrics */}
                        <div className='text-xl pb-4'>Average Score</div>
                        <div> {(textMetrics.reduce((a, b) => a + b.score, 0) / textMetrics.length) } / 5</div>
                        
                        {/* <div>{metric.description}</div> */}
                      </div>
                  </div>
            </div>
          </div>} />
        <Route path="/" element={<div className="container mx-auto my-8">     
            <div>
              <div className='text-3xl'>RhetoriqAI Speakers Demo</div>
              <div className='text-xl'>
                {speakers.map((speaker) => (
                  <button className={`${speaker.name == selectedSpeaker ? 'bg-purple-500' : "bg-gray-500" } hover:bg-purple-700 text-white font-bold py-2 px-4 rounded-full m-2`} onClick={() => setSelectedSpeaker(speaker.name)}>{speaker.name}</button>
                ))}
              </div>
            </div>

            <ChatWindow messages={messages} onSubmit={handleSubmit} onMetrics={handleMetrics} />
          </div>} />
      </Routes>
    </div>
  );
}

export default App;
