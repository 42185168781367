import React from 'react';
import ChatMessages from './ChatMessages';
import MessageInputForm from './MessageInputForm';

function ChatWindow(props) {
  const { messages, onSubmit, onMetrics } = props;

  return (
    <div className="flex flex-col justify-end h-full">
      <ChatMessages messages={messages} />
      <MessageInputForm onSubmit={onSubmit} onMetrics={onMetrics} />
    </div>
  );
}

export default ChatWindow;
